import {startLoading, stopLoading} from "../ajaxLoading";
import axios from "axios";
import {getLink} from "../functions";

export default class Hunters {

	constructor() {
		this.$members = document.querySelectorAll(".member");
		this.$roleBtns = document.querySelectorAll('.member .role-btn');
		this.$crystalInputs = document.querySelectorAll('.member .crystals');
		this.$points = document.getElementById('points');
		if (this.$points) {
			this.maxPoints = parseInt(this.$points.dataset.max);
			this.spyCounter = 0;
			this.hunterCounter = 0;
			this.crystalCounter = 0;
		}

		this.$flagBtns = document.querySelectorAll('.member .flag');
	}

	init() {
		this.$flagBtns.forEach(btn => {
			const memberId = parseInt(btn.dataset.member);
			const teamId = parseInt(btn.dataset.team);
			btn.addEventListener('click', () => {
				axios.post('/hunters/flag', {memberId, teamId})
					.then(response => {
						if (response.data.success) {
							window.location.reload();
						}
					});
			});
		});

		this.$roleBtns.forEach(btn => {
			/**
			 *
			 * @type {HTMLInputElement}
			 */
			const input = document.getElementById(btn.getAttribute('for'));
			if (input.checked) {
				if (input.value === 'detective') {
					this.spyCounter++;
				}
				if (input.value === 'hunter') {
					this.hunterCounter++;
				}
			}
			input.addEventListener('change', () => {
				this.recount();
				this.recountCrystals();
				this.hideBtns();
			});
		});
		this.$crystalInputs.forEach(input => {
			input.addEventListener('change', () => {
				if (parseInt(input.value) > parseInt(input.max)) {
					input.value = input.max;
				}
				this.recountCrystals();
				this.hideBtns();
			})
		})
		this.hideBtns();
	}

	recountCrystals() {
		this.crystalCounter = 0;
		this.$crystalInputs.forEach(input => {
			const id = parseInt(input.dataset.id);
			if (document.getElementById("role-" + id + "-guard").checked) {
				this.crystalCounter += parseInt(input.value);
			}
		});
		this.$points.innerText = this.maxPoints - this.crystalCounter;
	}

	recount() {
		this.spyCounter = 0;
		this.hunterCounter = 0;
		this.$roleBtns.forEach(btn => {
			/**
			 *
			 * @type {HTMLInputElement}
			 */
			const input = document.getElementById(btn.getAttribute('for'));
			if (input.checked) {
				if (input.value === 'detective') {
					this.spyCounter++;
				}
				if (input.value === 'hunter') {
					this.hunterCounter++;
				}
			}
		});
		console.log(this.spyCounter, this.hunterCounter)
	}

	hideBtns() {
		this.$roleBtns.forEach(btn => {
			/**
			 *
			 * @type {HTMLInputElement}
			 */
			const input = document.getElementById(btn.getAttribute('for'));
			const id = parseInt(btn.dataset.id);
			const crystals = document.getElementById('crystals-' + id + '-wrapper');

			if (input.value === 'detective') {
				if (!input.checked && this.spyCounter > 0) {
					btn.classList.add('d-none');
				}
				else {
					btn.classList.remove('d-none');
				}
				return;
			}
			if (input.value === 'hunter') {
				if (!input.checked && this.hunterCounter === 1) {
					btn.classList.add('d-none');
				}
				else {
					btn.classList.remove('d-none');
				}
			}
		});
	}
}
