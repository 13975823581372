import axios from "axios";

export default class IndexPage {

	constructor() {
		this.$cyphersWrapper = document.getElementById('cyphers');
		console.log(this.$cyphersWrapper);
		this.$cyphers = document.querySelectorAll('.cypher.decoding');
		this.intervals = [];
		setInterval(() => {
			this.refresh();
		}, 10000);
	}

	init() {
		this.$cyphers.forEach(cypher => {
			const timer = cypher.querySelector('.remaining');
			const message = cypher.querySelector('.message');
			const background = cypher.querySelector('.background');

			let remaining = parseInt(timer.dataset.remaining);
			let percent = parseFloat(background.dataset.percent);
			const percentIncrement = (1-percent)/remaining/10;
			let text = message.innerText;
			this.intervals.push(setInterval(() => {
				percent += percentIncrement;
				background.style = "width: " + (100*percent).toString() + "%;";
			}, 100));
			this.intervals.push(setInterval(() => {
				let charIndex = Math.floor(Math.random() * text.length);
				let char = text.charAt(charIndex);
				let next = char;
				while (char === next) {
					next = String.fromCharCode(Math.floor(65 + Math.random() * 26));
				}
				message.innerText = text.substring(0, charIndex) + next + text.substring(charIndex + 1);
			}, 200));
			this.intervals.push(setInterval(() => {
				remaining--;

				if (remaining <= 0) {
					this.refresh();
					return;
				}

				let parts = ["Zbývá:"];
				if (remaining >= 31536000) {
					parts.push(Math.floor(remaining / 31536000).toString() + " let");
				}
				if (remaining >= 86400) {
					parts.push(Math.floor((remaining % 31536000) / 86400).toString() + " dní");
				}
				if (remaining >= 3600) {
					parts.push(Math.floor((remaining % 86400) / 3600).toString() + " hodin");
				}
				if (remaining >= 60) {
					parts.push(Math.floor((remaining % 3600) / 60).toString() + " minut");
				}
				if (remaining % 60 > 0) {
					parts.push(((remaining % 60)).toString() + " sekund");
				}
				timer.innerText = parts.join(' ');
			}, 1000));
		});
	}

	refresh() {
		axios.get('/indicie')
			.then(response => {
				this.$cyphersWrapper.innerHTML = response.data;
				this.intervals.forEach(interval => {
					clearInterval(interval);
				});
				this.intervals = [];
				this.$cyphers = document.querySelectorAll('.cypher.decoding');
				this.init();
			})
	}

}

/**
 * Convert text HTML to Node
 * @param text : String
 * @returns {ChildNode}
 */
function textToNode(text) {
	const elem = document.createElement('div');
	elem.innerHTML = text;
	return elem.firstElementChild;
}
