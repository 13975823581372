import {startLoading, stopLoading} from "../ajaxLoading";
import axios from "axios";
import {render} from "../editor";

export default class AdminStezka {

	constructor() {
		this.$newBtn = document.getElementById('new-task');
		this.$textArea = document.getElementById('task');
	}

	init() {
		if (this.$newBtn) {
			this.$newBtn.addEventListener('click', () => {
				startLoading();
				axios.post('/admin/stezka', {})
					.then(response => {
						stopLoading(response.data.success);
						if (response.data.success) {
							window.location = '/admin/stezka/' + response.data.id;
						}
					})
					.catch(() => {
						stopLoading(false);
					})
			});
		}

		if (this.$textArea) {
			render('textarea#task');
		}
	}
}
