import axios from "axios";
import {startLoading, stopLoading} from "../ajaxLoading";

export default class Store {

	constructor() {
		this.points = document.getElementById('crystals');
		this.amount = document.getElementById('amount');
		this.btns = document.querySelectorAll('.transfer');
	}

	init() {
		this.btns.forEach(btn => {
			const id = parseInt(btn.dataset.id);
			btn.addEventListener('click', () => {
				if (!confirm('Potvrdit převod?')) {
					return;
				}
				const amount = parseInt(this.amount.value);
				startLoading();
				axios.post('/store/transfer/' + id, {amount})
					.then(response => {
						stopLoading(response.data.success);
						if (response.data.success) {
							this.points.innerText = response.data.crystals;
							this.amount.max = response.data.crystals;
							this.amount.value = 1;
						}
					})
					.catch(() => {
						stopLoading(false);
					})
			});
		});
	}

}
