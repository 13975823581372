import {startLoading, stopLoading} from "../ajaxLoading";
import axios from "axios";
import {getLink} from "../functions";

export default class AdminIndicie {

	constructor() {
		this.$cards = document.querySelectorAll('.indicie');
		this.$newBtn = document.getElementById('new-indicie');
	}

	init() {
		this.$newBtn.addEventListener('click', () => {
			startLoading();
			axios.post('/admin/indicie', {})
				.then(response => {
					stopLoading(response.data.success);
					if (response.data.success) {
						window.location.reload();
					}
				})
				.catch(() => {
					stopLoading(false);
				})
		});

		this.$cards.forEach(card => {
			const id = parseInt(card.dataset.id);
			const deleteBtn = card.querySelector('.delete');
			const content = card.querySelector('textarea');
			const time = card.querySelector('input');

			const time1 = card.querySelector('.time-1');
			const time2 = card.querySelector('.time-2');
			const time3 = card.querySelector('.time-3');
			const time4 = card.querySelector('.time-4');

			deleteBtn.addEventListener('click', () => {
				axios.delete(deleteBtn.dataset.href)
					.then(response => {
						if (response.data.success) {
							card.remove();
						}
					});
			});

			content.addEventListener('change', update);
			time.addEventListener('change', update);
			time.addEventListener('input', () => {
				const value = parseInt(time.value);
				time1.innerText = this.calculateTime(value, 1);
				time2.innerText = this.calculateTime(value, 50);
				time3.innerText = this.calculateTime(value, 100);
				time4.innerText = this.calculateTime(value, 500);
			});

			function update() {
				startLoading(true);
				axios.post('/admin/indicie/' + id, {
					'content': content.value,
					'time': time.value,
				})
					.then(response => {
						stopLoading(response.data.success, true);
					})
					.catch(() => {
						stopLoading(false, true);
					})
			}
		});
	}

	/**
	 *
	 * @param time {Number}
	 * @param points {Number}
	 * @returns {string}
	 */
	calculateTime(time, points) {
		const remaining = Math.ceil(time / (points * 0.1));
		let parts = [];
		if (remaining >= 31536000) {
			parts.push(Math.floor(remaining / 31536000).toString() + " let");
		}
		if (remaining >= 86400) {
			parts.push(Math.floor((remaining % 31536000) / 86400).toString() + " dní");
		}
		if (remaining >= 3600) {
			parts.push(Math.floor((remaining % 86400) / 3600).toString() + " hodin");
		}
		if (remaining >= 60) {
			parts.push(Math.floor((remaining % 3600) / 60).toString() + " minut");
		}
		if (remaining % 60 > 0) {
			parts.push(((remaining % 60)).toString() + " sekund");
		}
		return parts.join(' ');
	}
}
