/**
 * Get the whole URL to given request
 *
 * @param {string[]} request
 *
 * @returns {string}
 */
export function getLink(request) {
	if (prettyUrl) {
		return window.location.origin + '/' + request.join('/');
	} else {
		let query = {
			lang: document.documentElement.lang
		};
		let i = 0;
		request.forEach(page => {
			if (page === '') {
				return;
			}
			query[`p[${i}]`] = page;
			i++;
		});
		const params = new URLSearchParams(query);
		return window.location.origin + "?" + params.toString();
	}
}

/**
 * Test if the string is a URL
 * @param str : string
 * @returns {boolean}
 */
export function validURL(str) {
	const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
		'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
		'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
		'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
		'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
		'(\\#[-a-z\\d_]*)?$','i'); // fragment locator
	return !!pattern.test(str);
}
