/* Import TinyMCE */
import tinymce from 'tinymce';

/* Default icons are required. After that, import custom icons if applicable */
import 'tinymce/icons/default';

/* Required TinyMCE components */
import 'tinymce/themes/silver';
import 'tinymce/models/dom';

/* Import a skin (can be a custom skin instead of the default) */
import 'tinymce/skins/ui/oxide/skin.css';

/* Import plugins */
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/code';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/save';
import 'tinymce/plugins/autosave';

/* Initialize TinyMCE */
export function render (selector) {
	tinymce.init({
		selector,
		plugins: 'advlist code emoticons link lists table save autosave',
		toolbar: 'save | bold italic | bullist numlist | link emoticons',
		menubar: 'edit insert view format table tools help',
		language: 'cs',
		language_url: '/assets/js/langs/cs.js',
	});
}
