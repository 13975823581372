import {startLoading, stopLoading} from "../ajaxLoading";
import axios from "axios";

let timeout = null;

export default function initAdminSvaciny() {
	document.querySelectorAll('input.svacina').forEach(input => {
		const id = input.dataset.id;
		console.log(input.dataset);
		input.addEventListener('input', () => {
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				startLoading(true);
				axios.post('/admin/svaciny/' + id, {name: input.value})
					.then(response => {
						stopLoading(response.data.success, true);
					})
					.catch(() => {
						stopLoading(false, true);
					})
			}, 1000);
		});
	});
}
