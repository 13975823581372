import * as bootstrap from 'bootstrap';
import axios from 'axios';
import {getLink, validURL} from "./functions";
import QrScanner from "qr-scanner";
//import QrScannerWorkerPath from '../../node_modules/qr-scanner/qr-scanner-worker.min.js';
import MembersAdmin from "./pages/MembersAdmin";
import {startLoading, stopLoading} from "./ajaxLoading";
import Admin from "./pages/Admin";
import Cypher from "./pages/Cypher";
import IndexPage from "./pages/IndexPage";
import '@lottiefiles/lottie-player';
import Hunters from "./pages/Hunters";
import AdminIndicie from "./pages/AdminIndicie";
import Store from "./pages/Store";
import Notes from "./pages/Notes";
import AdminStezka from "./pages/AdminStezka";
import AdminQuiz from "./pages/AdminQuiz";
import Quiz from "./pages/Quiz";
import initAdminSvaciny from "./pages/AdminSvaciny";

//QrScanner.WORKER_PATH = QrScannerWorkerPath;

/**
 * Replaces unicode characters with ASCII
 * @returns {String}
 */
String.prototype.toAscii = function () {
	let text = this;
	const unicode = "ÁÄČÇĎÉĚËÍŇÓÖŘŠŤÚŮÜÝŽáäčçďéěëíňóöřšťúůüýž".split('');
	const ascii = "AACCDEEEINOORSTUUUYZaaccdeeeinoorstuuuyz".split('');
	unicode.forEach((char, key) => {
		text = text.replaceAll(char, ascii[key]);
	});
	return text;
};


axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.get['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

window.addEventListener("load", () => {
	if (page.path[1] === 'admin' && page.path[2] === 'members') {
		const pageClass = new MembersAdmin();
		pageClass.init();
	} else if (page.path[1] === 'admin' && page.path[2] === 'indicie') {
		const pageClass = new AdminIndicie();
		pageClass.init();
	} else if (page.path[1] === 'admin' && page.path[2] === 'stezka') {
		const pageClass = new AdminStezka();
		pageClass.init();
	} else if (page.path[1] === 'admin' && page.path[2] === 'quiz') {
		const pageClass = new AdminQuiz();
		pageClass.init();
	} else if (page.path[1] === 'admin' && page.path[2] === 'svaciny') {
		initAdminSvaciny();
	} else if (page.path[1] === 'admin') {
		const pageClass = new Admin();
		pageClass.init();
	} else if (page.path[1] === 'hunters') {
		const pageClass = new Hunters();
		pageClass.init();
	} else if (page.path[1] === 'kviz') {
		const pageClass = new Quiz();
		pageClass.init();
	} else if (page.path[1] === 'notes') {
		const pageClass = new Notes();
		pageClass.init();
	} else if (page.path[1] === 'store') {
		const pageClass = new Store();
		pageClass.init();
	} else if (page.path[1] === 'cypher') {
		const pageClass = new Cypher();
		pageClass.init();
	} else if (page.path[1] === 'svacina') {
		const $save = document.getElementById('save');
		const $counts = document.querySelectorAll('.svacinaCount');
		$save.addEventListener('click', () => {
			let counts = {};
			$counts.forEach($count => {
				counts[$count.dataset.id] = $count.value;
			});
			startLoading();
			axios.post(getLink(['svacina', 'send']), {svaciny: counts})
				.then(res => {
					stopLoading(res.data.success);
					if (res.data.success) {
						window.location.reload();
					}
				})
				.catch(res => {
					console.error(res);
					stopLoading(false);
				})
		});
	} else if (page.path.length === 0) {
		const pageClass = new IndexPage();
		pageClass.init();
	}

	// QR scanner
	const qrButtons = document.querySelectorAll('.qr-scan');
	if (qrButtons) {
		qrButtons.forEach(btn => {
			let scanning = false;
			const qrModalElem = document.createElement("div");
			qrModalElem.classList.add('modal');
			qrModalElem.innerHTML = '<div class="modal-dialog modal-dialog-centered modal-lg"><div class="modal-content">' +
				'<div class="modal-header"><h5 class="modal-title">Scan QR kódů</h5><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Zavřít"></button></div>' +
				'<div class="modal-body"><video class="w-100"></video></div>' +
				'</div></div>';
			document.body.appendChild(qrModalElem);
			const qrVideo = qrModalElem.querySelector('video');
			const qrModal = new bootstrap.Modal(qrModalElem, {
				focus: false,
			});
			const qrScanner = new QrScanner(qrVideo, result => {
				console.log(result);
				if (validURL(result)) {
					window.location = result;
				}
				if (result.substring(0, 3) === 'in-') {
					startLoading();
					axios.post('/indicie/' + result.substring(3), {})
						.then(response => {
							stopLoading(response.data.success);
							if (response.data.success) {
								qrScanner.stop();
								scanning = false;
								qrModal.hide();
							}
						})
						.catch(() => {
							stopLoading(false);
						});
				}
				if (result.substring(0, 3) === 'st-') {
					startLoading();
					axios.post('/stezka/unlock/' + result.substring(3), {})
						.then(response => {
							stopLoading(response.data.success);
							if (response.data.success) {
								qrScanner.stop();
								scanning = false;
								qrModal.hide();
								window.location = response.data.link;
							}
						})
						.catch(() => {
							stopLoading(false);
						});
				}
			});
			qrModalElem.addEventListener('hidden.bs.modal', () => {
				if (scanning) {
					qrScanner.stop();
					scanning = false;
					console.log('stop');
				}
			});
			btn.addEventListener("click", () => {
				if (scanning) {
					qrScanner.stop();
					qrModal.hide();
					scanning = false;
				} else {
					qrModal.show();
					qrScanner.start();
					scanning = true;
				}
			});
		});
	}
});
