import {startLoading, stopLoading} from "../ajaxLoading";
import axios from "axios";
import {render} from "../editor";

export default class AdminQuiz {

	constructor() {
		this.$newBtn = document.getElementById('new-question');
		this.$textArea = document.getElementById('question');
		console.log(this.$textArea, this.$newBtn)
	}

	init() {
		if (this.$newBtn) {
			this.$newBtn.addEventListener('click', () => {
				startLoading();
				axios.post('/admin/quiz', {})
					.then(response => {
						stopLoading(response.data.success);
						if (response.data.success) {
							window.location = '/admin/quiz/' + response.data.id;
						}
					})
					.catch(() => {
						stopLoading(false);
					})
			});
		}

		if (this.$textArea) {
			render('textarea#question');
			const answersWrapper = document.getElementById('answers');
			/**
			 * @type {HTMLTemplateElement}
			 */
			const answerTemplate = document.getElementById('answerTemplate');
			const answerHtml = answerTemplate.innerHTML;
			const addAnswer = document.getElementById('addAnswer');
			let key = parseInt(addAnswer.dataset.key);

			document.querySelectorAll('.answer').forEach(wrapper => {
				this.initAnswer(wrapper);
			})

			addAnswer.addEventListener('click', () => {
				const answerDom = new DOMParser()
					.parseFromString(
						answerHtml.replaceAll('#', (++key).toString()),
						"text/html"
					).body.firstElementChild;
				answersWrapper.appendChild(answerDom);
				this.initAnswer(answerDom);
			});
		}
	}

	/**
	 *
	 * @param dom {Element|Node}
	 */
	initAnswer(dom) {
		const remove = dom.querySelector('.remove');
		render('#' + dom.id + ' textarea');
		remove.addEventListener('click', () => {
			dom.remove();
		})
	}
}
