import axios from "axios";

export default class Quiz {

	constructor() {
		this.$questions = document.querySelectorAll(".question");
		this.$questionButtons = document.querySelectorAll('.question-switch');
	}

	init() {
		if (!this.$questions || !this.$questionButtons) {
			return;
		}
		document.getElementById('end-quiz').addEventListener('click', e => {
			if (!confirm('Opravdu chcete ukončit kvíz? Už se nepůjde vrátit.')) {
				e.preventDefault();
			}
		})
		const timer = document.getElementById('timer');
		let left = parseInt(timer.dataset.left);
		timer.innerText = Math.floor(left / 60).toString().padStart(2, '0') + ':' + (left % 60).toString().padStart(2, '0');
		setInterval(() => {
			left--;
			timer.innerText = Math.floor(left / 60).toString().padStart(2, '0') + ':' + (left % 60).toString().padStart(2, '0');
			if (left <= 0) {
				const data = new FormData(document.querySelector('form'));
				data.append('end', 1);
				axios.post(window.location.href, data)
					.then(() => {
						window.location.reload();
					})
			}
		}, 1000);
		this.$questionButtons.forEach(btn => {
			const key = btn.dataset.key;
			const questionDom = document.getElementById('question-' + key);
			const inputs = questionDom.querySelectorAll('input');
			btn.addEventListener('click', () => {
				this.$questions.forEach(dom => {
					dom.classList.add('d-none');
				});
				questionDom.classList.remove('d-none');
				const currentBtn = document.querySelector(`[data-key="${currentKey}"]`);
				currentBtn.classList.remove('btn-success');
				currentBtn.classList.add(currentBtn.dataset.filled === '0' ? 'btn-secondary' : 'btn-info');
				currentKey = key;
				btn.classList.remove('btn-secondary', 'btn-info');
				btn.classList.add('btn-success');
			});

			inputs.forEach(input => {
				input.addEventListener('change', () => {
					checkFilled();
				});
			})

			function checkFilled() {
				let filled = false;
				inputs.forEach(input => {
					if (input.checked) {
						filled = true;
					}
				})
				if (filled) {
					btn.dataset.filled = '1';
					if (key !== currentKey) {
						btn.classList.remove('btn-secondary');
						btn.classList.add('btn-info');
					}
				} else {
					btn.dataset.filled = '0';
					if (key !== currentKey) {
						btn.classList.add('btn-secondary');
						btn.classList.remove('btn-info');
					}
				}
			}
		});
	}
}
