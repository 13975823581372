import {startLoading, stopLoading} from "../ajaxLoading";
import axios from "axios";
import {getLink} from "../functions";

const teamColors = ['red', 'blue', 'green', 'yellow', 'purple'];

export default class MembersAdmin {

	constructor() {
		this.$members = document.querySelectorAll(".member");
	}

	init() {
		this.$members.forEach($member => {
			const $rfid = $member.querySelector('.rfid');
			$rfid.addEventListener('input', () => {
				startLoading(true);
				const [x, member] = $rfid.id.split('-');
				axios.post(getLink(['admin', 'members', 'rfid']), {member, rfid: $rfid.value})
					.then(res => {
						stopLoading(res.data.success, true);
					})
					.catch(res => {
						console.error(res);
						stopLoading(false, true);
					})
			});
			const teamSelect = $member.querySelectorAll('.btn-check');
			teamSelect.forEach(check => {
				check.addEventListener("change", () => {
					if (check.checked) {
						teamColors.forEach(color => {
							$member.classList.remove(`bg-${color}-500`, `text-bg-${color}-500`);
						});
						const [x, member, color] = check.id.split('-');
						let team = check.value;
						startLoading();
						axios.post(getLink(['admin', 'members', 'setteam']), {member, team})
							.then(res => {
								stopLoading(res.data.success);
								if (res.data.success) {
									$member.classList.remove('bg-red-200');
									$member.classList.add(`bg-${color}-500`, `text-bg-${color}-500`);
								}
							})
							.catch(res => {
								console.error(res);
								stopLoading(false);
							})
					}
				});
			});
		});
	}
}
