import {render} from "../editor";

export default class Notes {

	constructor() {
		this.editor = document.getElementById('notes');
	}

	init() {
		render('textarea#notes');
	}

}
