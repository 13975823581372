import {startLoading, stopLoading} from "../ajaxLoading";
import axios from "axios";
import {getLink} from "../functions";

export default class Admin {

	constructor() {
		this.$pointButtons = document.querySelectorAll('.addPoints');
		this.$customPointButtons = document.querySelectorAll('.addCustomPoints');
		this.$stars = document.querySelectorAll('.starCount');
	}

	init() {
		if (this.$pointButtons) {
			this.$pointButtons.forEach($btn => {
				const id = $btn.dataset.id;
				const count = parseInt($btn.dataset.count);
				$btn.addEventListener('click', () => {
					startLoading()
					axios.post(getLink(['admin', 'points', id]), {count})
						.then(res => {
							stopLoading(res.data.success);
							if (res.data.success) {
								window.location.reload();
							}
						})
						.catch(res => {
							console.error(res);
							stopLoading(false);
						});
				});
			});
		}
		if (this.$customPointButtons) {
			this.$customPointButtons.forEach(btn => {
				const input = btn.parentElement.querySelector('input');
				const id = input.dataset.id;
				btn.addEventListener('click', () => {
					const count = parseInt(input.value);
					if (isNaN(count) || count === 0) {
						alert('Nevalidní hodnota');
						return;
					}
					startLoading()
					axios.post(getLink(['admin', 'points', id]), {count})
						.then(res => {
							stopLoading(res.data.success);
							if (res.data.success) {
								window.location.reload();
							}
						})
						.catch(res => {
							console.error(res);
							stopLoading(false);
						});
				});
			});
		}
		if (this.$stars) {
			this.$stars.forEach($input => {
				const id = $input.dataset.id;
				$input.addEventListener('change', () => {
					const count = $input.value;
					startLoading()
					axios.post(getLink(['admin', 'stars', id]), {count})
						.then(res => {
							stopLoading(res.data.success);
							if (res.data.success) {
								window.location.reload();
							}
						})
						.catch(res => {
							console.error(res);
							stopLoading(false);
						});
				});
			});
		}
	}
}
