import {startLoading, stopLoading} from "../ajaxLoading";
import axios from "axios";
import {getLink} from "../functions";

export default class Cypher {

	constructor() {
		this.$inputs = document.querySelectorAll(".cypherChar");
		this.$cypher = document.getElementById('cypher');
	}

	init() {
		if (this.$inputs) {
			this.$inputs.forEach($input => {
				const ord = $input.dataset.ord;
				const orig = $input.dataset.orig;
				const deciphered = $input.dataset.deciphered.trim();
				const $chars = document.querySelectorAll(`.ch-${ord}`);

				if ($chars.length === 0) {
					$input.parentNode.parentNode.classList.add('d-none');
				}

				$input.addEventListener("input", () => {
					if ($input.value.trim().toAscii() === deciphered) {
						$input.classList.add('bg-success');
						$input.classList.remove('bg-info');
					}
					else if ($input.value !== orig) {
						$input.classList.add('bg-info');
						$input.classList.remove('bg-success');
					}
					else {
						$input.classList.remove('bg-info', 'bg-success');
					}
					$chars.forEach($char => {
						$char.innerHTML = $input.value;
						if ($input.value === deciphered) {
							$char.classList.add('text-success');
							$char.classList.remove('text-info');
						}
						else if ($input.value !== orig) {
							$char.classList.add('text-info');
							$char.classList.remove('text-success');
						}
						else {
							$char.classList.remove('text-info', 'text-success');
						}
					});
					this.checkValid();
				});
			});
		}
	}

	checkValid() {
		const validText = 'Geologove, biologove a archeologove z organizace MIRA pri prozkoumavani mimozemske relikvie byli napadeni mimozemskymi parazity. Ti se diky svym schopnostem ukryli v napadenych crewmatech a pote, co se ve vesmirne lodi Skeld vydali na velitelstvi nebo zakladnu Polus, zacali radit. O tom, jak to dopadne a kdo vyhraje, rozhodujete uz vy.'.toAscii().toLowerCase();
		const decipheredText = this.$cypher.innerText.toAscii().toLowerCase();
		if (validText === decipheredText) {
			document.querySelectorAll('.text-info').forEach($char => {
				$char.classList.remove('text-info');
			})
			this.$cypher.classList.add('text-success');
			axios.post(getLink(['cypher', 'validate']))
				.then(res => {
					if (res.data.success) {
						setTimeout(() => {
							window.location = getLink(['']);
						}, 5000);
					}
				})
		}
	}
}
